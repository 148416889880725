import {ChangeEvent, FC, Fragment, useCallback, useContext, useEffect, useState} from "react";
import PersonContext from "../../store/person-context";
import useHttp from "../../hooks/use-http";
import FormHeader from "../layout/FormHeader";
import BackButton from "../ui/BackButton";
import NextButton from "../ui/NextButton";
import FormContent from "../layout/FormContent";
import classes from "./CreateAccount.module.css";
import {
    annualRevenueOptions,
    getIndustriesUrl,
    numberOfEmployeesOptions,
    organizationTypesOptions,
    updatePersonProperty,
    findPersonPropertyValue
} from "../../utils/Constants";
import {Industry, IndustryDetail} from "../../model/Industry";
import {PersonProperty} from "../../model/Person";

const CreateAccountStep3: FC<{goToNextPage: Function, goToPreviousPage: Function}> = ({goToNextPage, goToPreviousPage}) => {
    const {person, updateStep3Details, showBiography} = useContext(PersonContext);
    const [biography, setBiography] = useState(findPersonPropertyValue(23, person));
    const [businessDescription, setBusinessDescription] = useState(findPersonPropertyValue(3, person));
    const [organizationTypeValue, setOrganizationTypeValue] = useState(findPersonPropertyValue(7, person));
    const [annualRevenueValue, setAnnualRevenueValue] = useState(findPersonPropertyValue(5, person));
    const [numberOfEmployeesValue, setNumberOfEmployeesValue] = useState(findPersonPropertyValue(4, person));
    const [industryValue, setIndustryValue] = useState(findPersonPropertyValue(33, person));
    const [industryDetailValue, setIndustryDetailValue] = useState(findPersonPropertyValue(34, person));
    const [industries, setIndustries] = useState<Industry[]>([]);
    const [industryDetails, setIndustryDetails] = useState<IndustryDetail[]>([]);
    const {sendRequest: getIndustries} = useHttp();

    const updateIndustryDetailsList = useCallback((industries: Industry[]) => {
        const industryDetails = industries.find(i => i.code === +industryValue)?.industryDetail || [];
        setIndustryDetails(industryDetails);
    }, [industryValue]);
    useEffect(() => {
        const updateIndustriesList = (response: any) => {
            const industries: Industry[] = response.data.industries;
            setIndustries(industries);
            updateIndustryDetailsList(industries);
        };
        industries.length === 0
            ? getIndustries({ url: getIndustriesUrl }, updateIndustriesList)
            : updateIndustryDetailsList(industries);

    }, [getIndustries, industries, industries.length, updateIndustryDetailsList]);
    const biographyChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setBiography(e.target.value);
    }
    const businessDescriptionChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setBusinessDescription(e.target.value);
    }
    const organizationTypeChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        setOrganizationTypeValue(e.target.value);
    }
    const annualRevenueChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        setAnnualRevenueValue(e.target.value);
    }
    const numberOfEmployeesChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        setNumberOfEmployeesValue(e.target.value);
    }
    const industryChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        setIndustryValue(e.target.value);
        setIndustryDetailValue('');
    }
    const industryDetailChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        setIndustryDetailValue(e.target.value);
    }
    const prevButtonClickHandler = () => {
        updatePersonContext().then(() => {
            goToPreviousPage();
        });
    }
    const nextButtonClickHandler = () => {
        updatePersonContext().then(() => {
            goToNextPage();
        });
    }
    const updatePersonContext = async () =>  {
        const updatedPersonProperties: PersonProperty[] = person!.properties;
        updatePersonProperty(person!, updatedPersonProperties, 23, biography);
        updatePersonProperty(person!, updatedPersonProperties, 3, businessDescription);
        updatePersonProperty(person!, updatedPersonProperties, 7, organizationTypeValue);
        updatePersonProperty(person!, updatedPersonProperties, 5, annualRevenueValue);
        updatePersonProperty(person!, updatedPersonProperties, 4, numberOfEmployeesValue);
        updatePersonProperty(person!, updatedPersonProperties, 33, industryValue);
        updatePersonProperty(person!, updatedPersonProperties, 34, industryDetailValue);

        await updateStep3Details(updatedPersonProperties);
    }
    return (
        <Fragment>
            <FormHeader
                heading="Complete your profile"
                subheading="Your profile makes it easy for others to connect with you within the Vistage community."
            />
            <FormContent>
                {showBiography &&
                    <Fragment>
                        <div className={classes.row}>
                            <h6>Share briefly your work history, background and interests.</h6>
                        </div>
                        <div className={`${classes.row} ${classes['label-with-user-input']}`}>
                            <label>Biography</label>
                            <textarea value={biography} onChange={biographyChangeHandler} maxLength={2000} rows={5}/>
                        </div>
                    </Fragment>
                }
                <div className={classes.row}>
                    <h6>Share briefly your company's purpose, products and services.</h6>
                </div>
                <div className={`${classes.row} ${classes['label-with-user-input']}`}>
                    <label>Business Description</label>
                    <textarea value={businessDescription} onChange={businessDescriptionChangeHandler} maxLength={2000} rows={5}/>
                </div>
                <div className={`${classes.row} ${classes['label-with-user-input']}`}>
                    <label>Type of organization</label>
                    <select value={organizationTypeValue} onChange={organizationTypeChangeHandler}>
                        {organizationTypesOptions.map(({ text, value }) =>
                            <option value={value} key={value}>{text}</option>
                        )}
                    </select>
                </div>
                <div className={`${classes.row} ${classes['label-with-user-input']}`}>
                    <label>Annual Revenue</label>
                    <select value={annualRevenueValue} onChange={annualRevenueChangeHandler}>
                        {annualRevenueOptions.map(({ text, value }) =>
                            <option value={value} key={value}>{text}</option>
                        )}
                    </select>
                </div>
                <div className={`${classes.row} ${classes['label-with-user-input']}`}>
                    <label>Number of employees</label>
                    <select value={numberOfEmployeesValue} onChange={numberOfEmployeesChangeHandler}>
                        {numberOfEmployeesOptions.map(({ text, value }) =>
                            <option value={value} key={value}>{text}</option>
                        )}
                    </select>
                </div>
                <div className={`${classes.row} ${classes['label-with-user-input']}`}>
                    <label>Industry</label>
                    <select value={industryValue} onChange={industryChangeHandler}>
                        <option value="">Select...</option>
                        {industries.map(({ code, description }) =>
                            <option value={code} key={code}>{description}</option>
                        )}
                    </select>
                </div>
                <div className={`${classes.row} ${classes['label-with-user-input']}`}>
                    <label>Industry detail</label>
                    <select value={industryDetailValue} onChange={industryDetailChangeHandler}>
                        <option value="">Select...</option>
                        {industryDetails.map(({ code, description }) =>
                            <option value={code} key={code}>{description}</option>
                        )}
                    </select>
                </div>
                <div className={classes['form-action-buttons-row']}>
                    <BackButton onClickHandler={prevButtonClickHandler}/>
                    <NextButton onClickHandler={nextButtonClickHandler}/>
                </div>
            </FormContent>
        </Fragment>
    );
};

export default CreateAccountStep3;
