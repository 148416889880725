import React, {Context, createContext, FC, useCallback, useState} from "react";
import useHttp from "../hooks/use-http";
import {validateTokenUrl} from "../utils/Constants";

type UserToken = {
    token: string;
    associatedId: string;
    created: Date;
    expiration: Date;
    issuedFor: string;
    ipConsumed: string;
    consumption: Date;
    systemIdentityNumeric: number;
    systemIdentityId: number;
    termsAcceptedVersion: string;
    isLoading: boolean,
    error: string|null
}
type UserTokenContextObj = {
    userToken: UserToken | undefined,
    getUserToken: (token: string | undefined) => void,
    isLoading: boolean,
    error: string|null
}
const UserTokenContext: Context<UserTokenContextObj> = createContext<UserTokenContextObj>({
    userToken: undefined,
    getUserToken: (token: string | undefined) => {},
    isLoading: false,
    error: ''
});
export const UserTokenContextProvider: FC = (props) => {
    const [userToken, setUserToken] = useState<UserToken>();
    const { isLoading, error, sendRequest: getToken } = useHttp();
    const getUserTokenHandler = useCallback(async (token: string | undefined) => {
        const handleGetUserTokenResponse = (response: any) => {
            setUserToken(response.data);
        }
        getToken({
            url: validateTokenUrl,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: { 'token': token }
        }, handleGetUserTokenResponse, () => {});
        return;
    }, [getToken]);
    const context = {
        userToken: userToken,
        getUserToken: getUserTokenHandler,
        isLoading: isLoading,
        error: error
    }
    return (
        <UserTokenContext.Provider value={context}>
            {props.children}
        </UserTokenContext.Provider>
    );
}

export default UserTokenContext;
