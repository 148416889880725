import {FC, useContext, useEffect} from "react";
import IFrameLink from "../ui/IFrameLink";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from "../../store/app-context";
import classes from "./Footer.module.css";
import { faMessage } from '@fortawesome/free-regular-svg-icons';

const Footer: FC = () => {

    const {config} = useContext(AppContext);
    const privacyPolicyUrl: string|undefined = config?.privacyPolicyUrl;
    const termsOfUseUrl: string|undefined = config?.termsOfUseUrl;
    const liveChatUrl: string|undefined = config?.liveChatUrl;
    const date: Date = new Date();

    useEffect(() => {
        const googleTranslate = document.getElementById('google_translate_element');
        if (!googleTranslate) {
            return;
        }
        const googleTranslateElementInitScript = document.createElement("script");
        googleTranslateElementInitScript.type = "text/javascript";
        googleTranslateElementInitScript.innerHTML = "function googleTranslateElementInit() { new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element'); } ";
        googleTranslate.appendChild(googleTranslateElementInitScript);

        const googleTranslateElementScript = document.createElement("script");
        googleTranslateElementScript.type = "text/javascript";
        googleTranslateElementScript.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        googleTranslate.appendChild(googleTranslateElementScript);
    }, []);

    return (
        <div className={classes.footer}>
            <div className={classes.links}>
                <IFrameLink text="Privacy Policy" url={privacyPolicyUrl} />
                |
                <IFrameLink text="Terms of Use" url={termsOfUseUrl} />
            </div>
            <div className={classes.copyright}>
                ©{date.getFullYear()}&nbsp;<a href={"https://www.vistage.com"} target={"_blank"} rel={"noreferrer"}>Vistage Worldwide</a>, Inc., All Rights Reserved. My Vistage
            </div>
            <div className={classes['gtranslate-and-chatbox']}>
                <div id="google_translate_element" />
                <div className={classes.chatbox}>
                    <a href={liveChatUrl} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faMessage} size={"2x"} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
