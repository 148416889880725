import React, {FC, useContext, useEffect} from 'react';
import AppContext from "./store/app-context";
import './App.css';
import Container from "./components/layout/Container";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const App: FC = () => {
    const {setAppContext} = useContext(AppContext);
    useEffect(() => {
        setAppContext();
    }, [setAppContext])
    return (
        <Container />
    );
};

export default App;
