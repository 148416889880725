import {FC, FormEvent, Fragment, useContext, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import useInput from "../hooks/use-input";
import useHttp from "../hooks/use-http";
import FormHeader from "./layout/FormHeader";
import FormContent from "./layout/FormContent";
import {isNotEmpty, isUsernameValid, resetUsernameUrl, validateUsernameUrl} from "../utils/Constants";
import Input from "./ui/Input";
import LoadingSpinner from "./ui/LoadingSpinner";
import SupportHelpText from "./SupportHelpText";
import classes from "./Forms.module.css"
import AppContext from "../store/app-context";
import UserTokenContext from "../store/user-token-context";

const INVALID_TOKEN_CODE = 403;
const INVALID_USERNAME_CODE = 400;

const ResetUsername: FC = () => {
    const { token } = useParams();
    const [usernameParam] = useSearchParams();
    const navigate = useNavigate();
    const { config } = useContext(AppContext);
    const { userToken } = useContext(UserTokenContext);
    const oldUsernameValue: string = usernameParam.get('username') || '';
    const [formHasError, setFormHasErrors] = useState(false);
    const [formError, setFormError] = useState('');
    const redirectUrl = `${config?.myVistageUrl}/?usernamechanged=true`;
    const fallbackRedirectUrl = `${config?.myVistageUrl}/?usernamechanged=false`;
    const {
        isLoading: httpIsLoading,
        sendRequest: httpSendRequest
    } = useHttp();
    const isOldUsername = (value: string): boolean => {
        return oldUsernameValue ? oldUsernameValue === value : false;
    }
    const isConfirmUsernameValid = (value: string): boolean => {
        if (usernameValue) {
            return isNotEmpty(value) && value === usernameValue;
        }
        return isNotEmpty(value) && isUsernameValid(value);
    }
    const {
        value: usernameValue,
        hasError: usernameHasError,
        valueChangeHandler: usernameChangeHandler
    } = useInput('', isUsernameValid);
    const {
        value: confirmUsernameValue,
        hasError: confirmUsernameHasError,
        valueChangeHandler: confirmUsernameChangeHandler
    } = useInput('', isConfirmUsernameValid);
    const scrollToTop = () => {
        document.getElementById('app-header')?.scrollIntoView({behavior: 'smooth'});
    }
    const setFormErrorValues = (error: string) => {
        setFormHasErrors(true);
        setFormError(error);
        scrollToTop();
    }
    const resetUsername = () => {
        httpSendRequest({
            url: resetUsernameUrl,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: {
                'token': token,
                'newUsername': usernameValue }
        }, () => {
            window.location.href = redirectUrl;
        }, (error: any) => {
            switch (+error.statusCode) {
                case INVALID_USERNAME_CODE:
                    window.location.href = fallbackRedirectUrl;
                    return;
                case INVALID_TOKEN_CODE:
                default:
                    navigate('/forgot-username?tokenInvalid=true');
                    return;
            }
        });
    }
    const submitHandler = (e: FormEvent) => {
        e.preventDefault();
        setFormError('');
        if (usernameHasError || confirmUsernameHasError) {
            setFormErrorValues('Please fill in the required field(s)');
            return;
        }
        if (usernameValue === oldUsernameValue) {
            setFormErrorValues('You entered your old username. Please try again.');
            return;
        }
        httpSendRequest({
            url: validateUsernameUrl(+userToken!.associatedId),
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: { "username": usernameValue }
        }, () => {
            setTimeout(() => { resetUsername(); });
        }, (error: any) =>{
            switch (+error.statusCode) {
                case 409:
                    setFormHasErrors(true);
                    setFormError('That username is already taken, sorry.  Please select a different username.');
                    scrollToTop();
                    return;
                default:
                    setFormHasErrors(true);
                    setFormError('Username validation is temporarily unavailable. Please try again in a few minutes.');
                    scrollToTop();
                    return;
            }
        })
    }
    return (
        <Fragment>
            {httpIsLoading && <LoadingSpinner />}
            <div className={classes['reset-username']}>
                <FormHeader heading={"Reset username"} />
                <FormContent>
                    <form onSubmit={submitHandler}>
                        {formHasError && <p className={classes['form-error-description']}>{formError}</p>}
                        <div className={classes.row}>
                            Usernames must meet this criteria:
                            <ul>
                                <li>Usernames must be 6+ character</li>
                                <li>You may use letters, numbers and symbols +, -, _, @ and . </li>
                                <li>Select something easy to remember, like an email address</li>
                            </ul>
                        </div>
                        <Input
                            inputLabel={"New username"}
                            inputType={"text"}
                            inputRequired={true}
                            inputReadOnly={false}
                            inputValue={usernameValue}
                            inputStyle={formHasError
                                && (usernameHasError || isOldUsername(usernameValue)) ? 'input-textbox-error' : ''}
                            inputOnChange={usernameChangeHandler}
                            maxLength={150}
                        />
                        {formHasError && usernameHasError &&
                            <p className={classes['error-description']}>Username must meet the above criteria</p>
                        }
                        <Input
                            inputLabel={"Verify new username"}
                            inputType={"text"}
                            inputRequired={true}
                            inputReadOnly={false}
                            inputValue={confirmUsernameValue}
                            inputStyle={formHasError && confirmUsernameHasError ? 'input-textbox-error' : ''}
                            inputOnChange={confirmUsernameChangeHandler}
                            maxLength={150}
                        />
                        {formHasError && confirmUsernameHasError &&
                            <p className={classes['error-description']}>Username and Verify values must match</p>
                        }
                        <div className={`${classes.row} ${classes['form-action-button']}`}>
                            <button type={"submit"} className={classes['submit-button']}>
                                Set Username
                            </button>
                        </div>
                        <SupportHelpText />
                    </form>
                </FormContent>
            </div>
        </Fragment>
    );
};

export default ResetUsername;
