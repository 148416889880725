import {FC, Fragment, useContext} from "react";
import {useParams} from "react-router-dom";
import FormHeader from "./layout/FormHeader";
import FormContent from "./layout/FormContent";
import classes from "./Forms.module.css";
import useHttp from "../hooks/use-http";
import {getAccountAutoLoginUrl} from "../utils/Constants";
import LoadingSpinner from "./ui/LoadingSpinner";
import AppContext from "../store/app-context";

type AutoLoginTokenResponse = {
    token: string
}

const ThankYou: FC = () => {
    const { token } = useParams();
    const { config } = useContext(AppContext);
    const androidDownloadUrl = `${config?.myVistageUrl}/getAndroid`;
    const iOSDownloadUrl = `${config?.myVistageUrl}/getIOS`;
    const videoUrl = config?.thankYouVideoUrl;
    const redirectUrl = `${config?.myVistageUrl}`;
    const fallbackUrl = `${config?.myVistageUrl}`;
    const {
        isLoading,
        sendRequest,
    } = useHttp();
    const viewProfileHandler = () => {
        if (!token) { return; }
        sendRequest({
            url: getAccountAutoLoginUrl(token)
        }, (response: any) => {
            const tokenResponse: AutoLoginTokenResponse = response.data;
            if (!tokenResponse) {
                window.location.href = fallbackUrl;
            }
            window.location.href = `${redirectUrl}/?token=${tokenResponse.token}&redirect_uri=${config?.myVistageUrl}/profile`;
        }, () => {
            window.location.href = fallbackUrl;
        });
    }
    return (
        <Fragment>
            <FormHeader heading={"Thank You!"} />
            <FormContent>
                {isLoading && <LoadingSpinner/>}
                <div className={classes.row}>
                    Your profile helps introduce you to the Vistage Community. Click to
                    <button className={classes['view-your-profile']} onClick={viewProfileHandler}>
                        &nbsp;view your online profile&nbsp;
                    </button>
                    where you can add a photo.
                </div>
                <div className={classes.row}>
                    Get the My Vistage mobile app for easy access to your group meetings and more!
                </div>
                <div className={`${classes.row} ${classes['mobile-app-url']}`}>
                    <a href={iOSDownloadUrl}>Download for iPhone</a>
                    <a href={androidDownloadUrl}>Download for Android</a>
                </div>
                <div className={classes.row}>
                    <iframe
                        className={classes['myvistage-video-iframe']}
                        src={videoUrl}
                        allowFullScreen={true}
                        title={"MyVistage"}
                        width={"100%"}
                        height="300"
                    />
                </div>
            </FormContent>
        </Fragment>
    );
};

export default ThankYou;
