import {FC} from "react";
import Modal from "./Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classes from "./LoadingSpinner.module.css";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const LoadingSpinner: FC = () => {
    return (
        <Modal>
            <div className={classes['loading-spinner']}>
                <FontAwesomeIcon icon={faSpinner} size={"4x"} transform="grow-2" pulse className={classes.spinner}/>
            </div>
        </Modal>
    );
};

export default LoadingSpinner;
