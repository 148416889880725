import {FC, MouseEventHandler} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from "./NextButton.module.css"
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

const NextButton: FC<{onClickHandler: MouseEventHandler}> = ({onClickHandler}) => {
    return (
        <button className={classes['next-button']} onClick={onClickHandler}>
            <span>Next</span>
            <FontAwesomeIcon icon={faAngleRight}/>
        </button>
    );
};

export default NextButton;
