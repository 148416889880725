import {FC, Fragment} from 'react';
import ReactDOM from 'react-dom';
import classes from './Modal.module.css';

const Backdrop: FC = () => {
    return <div className={classes.backdrop} />;
};

const ModalOverlay: FC = (props) => {
    return (
        <div className={classes['modal-overlay']}>{props.children}</div>
    );
};

const portalElement = document.getElementById('overlay')!;

const Modal: FC = (props) => {
    return (
        <Fragment>
            {ReactDOM.createPortal(<Backdrop />, portalElement)}
            {ReactDOM.createPortal(
                <ModalOverlay>{props.children}</ModalOverlay>,
                portalElement
            )}
        </Fragment>
    );
};

export default Modal;
