import {FC, Fragment} from "react";
import FormHeader from "./layout/FormHeader";
import FormContent from "./layout/FormContent";
import SupportHelpText from "./SupportHelpText";

const PageUnavailable: FC = () => {
    return (
        <Fragment>
            <FormHeader heading={"Page Unavailable"} />
            <FormContent>
                <div>
                    This page is temporarily unavailable. Please try again in a few minutes.
                </div>
                <SupportHelpText />
            </FormContent>
        </Fragment>
    )
};
export default PageUnavailable;
