import {ChangeEvent, FC, Fragment, useContext, useEffect, useState} from "react";
import classes from "./CreateAccount.module.css";
import BackButton from "../ui/BackButton";
import NextButton from "../ui/NextButton";
import FormHeader from "../layout/FormHeader";
import FormContent from "../layout/FormContent";
import PersonContext from "../../store/person-context";
import useHttp from "../../hooks/use-http";
import {getNetworksUrl} from "../../utils/Constants";
import {Network} from "../../model/Network";
import NetworksDropDown from "./NetworksDropDown";

const CreateAccountStep4: FC<{goToNextPage: Function, goToPreviousPage: Function}> = ({goToNextPage, goToPreviousPage}) => {
    const {networks, updateStep4Details} = useContext(PersonContext);
    const [selectedNetworks, setSelectedNetworks] = useState(networks);
    const [industryNetworks, setIndustryNetworks] = useState<Network[]>([]);
    const [affiliationNetworks, setAffiliationNetworks] = useState<Network[]>([]);
    const [interestNetworks, setInterestNetworks] = useState<Network[]>([]);
    const [geographicNetworks, setGeographicNetworks] = useState<Network[]>([]);
    const {sendRequest: getNetworks} = useHttp();
    useEffect(() => {
        const updateNetworks = (response: any) => {
            const networks: Network[] = response.data || [];
            setIndustryNetworks(networks.filter(network => network.category === 'Industry'));
            setAffiliationNetworks(networks.filter(network => network.category === 'Affiliation'));
            setInterestNetworks(networks.filter(network => network.category === 'Interest'));
            setGeographicNetworks(networks.filter(network => network.category === 'Geographic'));
        }
        getNetworks({
            url: getNetworksUrl
        }, updateNetworks);
    }, [getNetworks]);
    const industryNetworkSelectHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        const network = industryNetworks.find(network => network.id === +e.target.value);
        if (network) {
            setSelectedNetworks(prevState => [...prevState, network]);
        }
    }
    const affiliationNetworkSelectHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        const network = affiliationNetworks.find(network => network.id === +e.target.value);
        if (network) {
            setSelectedNetworks(prevState => [...prevState, network]);
        }
    }
    const interestNetworkSelectHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        const network = interestNetworks.find(network => network.id === +e.target.value);
        if (network) {
            setSelectedNetworks(prevState => [...prevState, network]);
        }
    }
    const geographicNetworkSelectHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        const network = geographicNetworks.find(network => network.id === +e.target.value);
        if (network) {
            setSelectedNetworks(prevState => [...prevState, network]);
        }
    }
    const removeSelectedNetwork = (id: number) => {
        setSelectedNetworks(prevState => prevState.filter(network => network.id !== id));
    }
    const isNetworkOptionDisabled = (id: number): boolean => {
        return selectedNetworks.find(network => network.id === id) !== undefined || false;
    }
    const prevButtonClickHandler = () => {
        updateNetworks().then(() => {
            goToPreviousPage();
        });
    }
    const updateNetworks = async () => {
        const sortedNetworks = selectedNetworks.sort(
            (n1, n2) => n1.name > n2.name ? 1 : -1);
        await updateStep4Details(sortedNetworks);
    }
    const nextButtonClickHandler = () => {
        updateNetworks().then(() => {
            goToNextPage();
        });
    }
    return (
        <Fragment>
            <FormHeader heading="Join Networks"/>
            <FormContent>
                <div className={classes.row}>
                    My Vistage Networks provide members with access to the experience and advice of their Vistage peers
                    through industry, affiliation, special interest, and geography based affinity communities.
                </div>
                <div className={classes.row}>
                    Select the Networks that you are interested in joining. Upon submission of this form you will be
                    automatically added to these networks and receive Welcome Emails. You may join as many as you want,
                    however most members find that 4-6 is optimal.
                </div>
                <NetworksDropDown
                    industryLabel={"Industry Networks"}
                    category={"Industry"}
                    networks={industryNetworks}
                    selectedNetworks={selectedNetworks}
                    onSelect={industryNetworkSelectHandler}
                    onRemove={removeSelectedNetwork}
                    canSelect={isNetworkOptionDisabled}
                />
                <NetworksDropDown
                    industryLabel={"Affiliation Networks"}
                    category={"Affiliation"}
                    networks={affiliationNetworks}
                    selectedNetworks={selectedNetworks}
                    onSelect={affiliationNetworkSelectHandler}
                    onRemove={removeSelectedNetwork}
                    canSelect={isNetworkOptionDisabled}
                />
                <NetworksDropDown
                    industryLabel={"Interest Networks"}
                    category={"Interest"}
                    networks={interestNetworks}
                    selectedNetworks={selectedNetworks}
                    onSelect={interestNetworkSelectHandler}
                    onRemove={removeSelectedNetwork}
                    canSelect={isNetworkOptionDisabled}
                />
                <NetworksDropDown
                    industryLabel={"Geographic Networks"}
                    category={"Geographic"}
                    networks={geographicNetworks}
                    selectedNetworks={selectedNetworks}
                    onSelect={geographicNetworkSelectHandler}
                    onRemove={removeSelectedNetwork}
                    canSelect={isNetworkOptionDisabled}
                />
                <div className={classes['form-action-buttons-row']}>
                    <BackButton onClickHandler={prevButtonClickHandler}/>
                    <NextButton onClickHandler={nextButtonClickHandler}/>
                </div>
            </FormContent>
        </Fragment>
    );
};

export default CreateAccountStep4;
