import {useCallback, useState} from "react";

const useHttp: () => { isLoading: boolean, error: any, statusCode: string|null, sendRequest: any } = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<any>(null);
    const [statusCode, setStatusCode] = useState<string|null>(null);
    const sendRequest = useCallback(async (requestConfig, applyData, applyError) => {
        setIsLoading(true);
        setError(null);
        try {
            const authHeader =  {
                // APV - 475, disable basic auth, preventing lastpass to save basic auth credentials
                // 'Authorization': 'Basic ' + new Buffer(`${process.env.REACT_APP_API_BASIC_AUTH}`).toString('base64')
            };
            const url = `${process.env.REACT_APP_API_URL}${requestConfig.url}`
            const response = await fetch(url, {
                method: requestConfig.method ? requestConfig.method : 'GET',
                headers: requestConfig.headers ? {...requestConfig.headers, ...authHeader} : authHeader,
                body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
            });
            setStatusCode(response.status.toString());
            const data = await response.json();
            if (response.status >= 200 && response.status < 299) {
                applyData(data);
            } else {
                setError('Request failed');
                applyError({
                    statusCode: response.status.toString(),
                    message: data
                });
            }
        } catch (err: any) {
            console.log(err.message);
            setError(err.message || 'Something went wrong!');
        }
        setIsLoading(false);
    }, []);

    return {
        isLoading: isLoading,
        error: error,
        statusCode: statusCode,
        sendRequest: sendRequest,
    };
};

export default useHttp;
