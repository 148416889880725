import {ChangeEvent, FC, Fragment, useContext, useEffect, useState} from "react";
import {Calendar} from 'primereact/calendar';
import PersonContext from "../../store/person-context";
import moment from "moment";
import useHttp from "../../hooks/use-http";
import useInput from "../../hooks/use-input";
import BackButton from "../ui/BackButton";
import FormHeader from "../layout/FormHeader";
import FormContent from "../layout/FormContent";
import NextButton from "../ui/NextButton";
import Input from "../ui/Input";
import EditProfileAddress from "./EditProfileAddress";
import {PersonProperty} from "../../model/Person";
import {Country} from "../../model/Country";
import classes from "./CreateAccount.module.css";
import {
    isNotEmpty,
    isEmailValid,
    getCountriesUrl,
    genderOptions,
    raceEthnicityOptions,
    findPersonPropertyValue,
    updatePersonProperty
} from "../../utils/Constants";
import * as React from "react";
import AppContext from "../../store/app-context";

const CreateAccountStep2: FC<{goToNextPage: Function, goToPreviousPage: Function}> = ({goToNextPage, goToPreviousPage}) => {
    const {person, updateStep2Details} = useContext(PersonContext);
    const { config } = useContext(AppContext);
    const [formHasError, setFormHasErrors] = useState(false);
    const [formError, setFormError] = useState('');
    const [isEditingAddress, setIsEditingAddress] = useState(false);

    const emailDefaultValue = person?.account.email || '';
    const isFederated = person?.account.isFederated || false;
    const workPhoneDefaultValue = findPersonPropertyValue(19, person);
    const jobTitleDefaultValue = findPersonPropertyValue(24, person);
    const companyName = findPersonPropertyValue(25, person);
    const spouseDefaultValue = findPersonPropertyValue(18, person);
    const genderOtherDefaultValue = findPersonPropertyValue(106, person);
    const [genderValue, setGenderValue] = useState(findPersonPropertyValue(92, person));
    const [raceEthnicityValue, setRaceEthnicityValue] = useState(findPersonPropertyValue(112, person));
    const raceEthnicityOtherDefaultValue = findPersonPropertyValue(113, person);
    const [address1, setAddress1] = useState(findPersonPropertyValue(8, person));
    const [address2, setAddress2] = useState(findPersonPropertyValue(9, person));
    const [city, setCity] = useState(findPersonPropertyValue(10, person));
    const [state, setState] = useState(findPersonPropertyValue(11, person));
    const [zip, setZip] = useState(findPersonPropertyValue(12, person));
    const [country, setCountry] = useState(findPersonPropertyValue(13, person));

    const minYear = config?.birthdayMinYear;
    const maxYear = config?.birthdayMaxYear;
    const defaultBirthDateValue = findPersonPropertyValue(91, person)
        ? new Date(moment(findPersonPropertyValue(91, person)).format('YYYY/MM/DD'))
        : undefined;
    const [birthdayValue, setBirthdayValue] = useState<Date|undefined>(defaultBirthDateValue);


    const [countries, setCountries] = useState<Country[]>([]);
    const {sendRequest: getCountries} = useHttp();
    useEffect(() => {
        const updateCountriesList = (response: any) => {
            setCountries(response.data.countries);
        }
        getCountries({
            url: getCountriesUrl
        }, updateCountriesList);
    }, [getCountries]);

    const isGenderOtherValueValid = (value: string): boolean => {
        return genderValue === 'Other' ? isNotEmpty(value) : true;
    }
    const isRaceEthnicityOtherValueValid = (value: string): boolean => {
        return raceEthnicityValue === 'Other' ? isNotEmpty(value) : true;
    }

    const {
        value: emailValue,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler
    } = useInput(emailDefaultValue, isEmailValid);
    const {
        value: workPhoneValue,
        hasError: workPhoneHasError,
        valueChangeHandler: workPhoneChangeHandler
    } = useInput(workPhoneDefaultValue, isNotEmpty);
    const {
        value: jobTitleValue,
        hasError: jobTitleHasError,
        valueChangeHandler: jobTitleChangeHandler
    } = useInput(jobTitleDefaultValue, isNotEmpty);
    const {
        value: spouseValue,
        valueChangeHandler: spouseChangeHandler
    } = useInput(spouseDefaultValue, () => {});
    const {
        value: genderOtherValue,
        hasError: genderOtherHasError,
        valueChangeHandler: genderOtherChangeHandler
    } = useInput(genderOtherDefaultValue, isGenderOtherValueValid);
    const {
        value: raceEthnicityOtherValue,
        hasError: raceEthnicityOtherHasError,
        valueChangeHandler: raceEthnicityOtherChangeHandler
    } = useInput(raceEthnicityOtherDefaultValue, isRaceEthnicityOtherValueValid);

    const selectedChangeDateHandler = (date: Date|null) => {
        setBirthdayValue(date ? new Date(date) : undefined);
    }
    const editAddressHandler = () => {
        setIsEditingAddress(true);
    }
    const updateAddressHandler = (address1: string, address2: string, city: string, state: string, zip: string, country: string) => {
        setAddress1(address1);
        setAddress2(address2);
        setCity(city);
        setState(state);
        setZip(zip);
        setCountry(country);
        setIsEditingAddress(false);
    }
    const genderChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        setGenderValue(e.target.value);
    }
    const raceEthnicityChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        setRaceEthnicityValue(e.target.value);
    }
    const nextButtonClickHandler = () => {
        setFormError('');
        if (isEditingAddress) {
            setFormError('Please save your edits before proceeding, or your work may be lost.');
            setFormHasErrors(true);
            scrollToTop();
            return;
        }
        if (emailHasError || workPhoneHasError || jobTitleHasError || genderOtherHasError || raceEthnicityOtherHasError) {
            setFormError('Please complete the following required field(s).')
            setFormHasErrors(true);
            scrollToTop();
            return;
        }
        updatePersonContext().then(() => {
            goToNextPage();
        });
    };
    const prevButtonClickHandler = () => {
        setFormError('');
        if (isEditingAddress) {
            setFormError('Please save your edits before proceeding, or your work may be lost.');
            setFormHasErrors(true);
            scrollToTop();
            return;
        }
        updatePersonContext().then(() => {
            goToPreviousPage();
        });
    };
    const updatePersonContext = async () =>  {
        const updatedPersonProperties: PersonProperty[] = person!.properties;
        updatePersonProperty(person!, updatedPersonProperties, 19, workPhoneValue);
        updatePersonProperty(person!, updatedPersonProperties, 24, jobTitleValue);
        updatePersonProperty(person!, updatedPersonProperties, 8, address1);
        updatePersonProperty(person!, updatedPersonProperties, 9, address2);
        updatePersonProperty(person!, updatedPersonProperties, 10, city);
        updatePersonProperty(person!, updatedPersonProperties, 11, state);
        updatePersonProperty(person!, updatedPersonProperties, 12, zip);
        updatePersonProperty(person!, updatedPersonProperties, 13, country);
        updatePersonProperty(person!, updatedPersonProperties, 18, spouseValue);
        updatePersonProperty(person!, updatedPersonProperties, 92, genderValue);
        updatePersonProperty(person!, updatedPersonProperties, 106, genderValue === 'Other' ? genderOtherValue : null);
        updatePersonProperty(person!, updatedPersonProperties, 91, birthdayValue ? birthdayValue : null);
        updatePersonProperty(person!, updatedPersonProperties, 112, raceEthnicityValue);
        updatePersonProperty(person!, updatedPersonProperties, 113, raceEthnicityValue === 'Other' ? raceEthnicityOtherValue : null);

        await updateStep2Details(emailValue, updatedPersonProperties);
    };
    const scrollToTop = () => {
        document.getElementById('app-header')?.scrollIntoView({behavior: 'smooth'});
    };
    return (
        <Fragment>
            <FormHeader
                heading="Welcome to Vistage"
                subheading="Please complete your contact information"
            />
            <FormContent>
                {formHasError && <p className={classes['form-error-description']}>{formError}</p>}
                <Input
                    inputLabel="Email"
                    inputType="email"
                    inputRequired={true}
                    inputReadOnly={isFederated}
                    inputValue={emailValue}
                    inputStyle={formHasError && emailHasError ? 'input-textbox-error' : ''}
                    inputOnChange={emailChangeHandler}
                    maxLength={100}
                />
                <Input
                    inputLabel="Work Phone"
                    inputType="tel"
                    inputRequired={true}
                    inputReadOnly={false}
                    inputValue={workPhoneValue}
                    inputStyle={formHasError && workPhoneHasError ? 'input-textbox-error' : ''}
                    inputOnChange={workPhoneChangeHandler}
                />
                <Input
                    inputLabel="Job Title"
                    inputType="text"
                    inputRequired={true}
                    inputReadOnly={false}
                    inputValue={jobTitleValue}
                    inputStyle={formHasError && jobTitleHasError ? 'input-textbox-error' : ''}
                    inputOnChange={jobTitleChangeHandler}
                />
                <Input
                    inputLabel="Company Name"
                    inputType="text"
                    inputRequired={true}
                    inputReadOnly={true}
                    inputValue={companyName.toUpperCase()}
                    inputStyle=""
                    inputOnChange={() => {}}
                />
                {!isEditingAddress &&
                    <div className={classes.row}>
                        <label className={classes.required}>Address</label>
                        {address1 && <p className={classes['address-readonly']}>{address1.toUpperCase()}</p>}
                        {address2 && <p className={classes['address-readonly']}>{address2.toUpperCase()}</p>}
                        <p className={classes['address-readonly']}>
                            {city && <span className={classes['address-readonly']}>{city.toUpperCase()}</span>}
                            {state && <span className={classes['address-readonly']}>, {state.toUpperCase()}</span>}
                            {zip && <span className={classes['address-readonly']}>, {zip.toUpperCase()}</span>}
                        </p>
                        {country && <p className={classes['address-readonly']}>{country.toUpperCase()}</p>}
                        <p><span className={classes['edit-address-link']} onClick={editAddressHandler}>Edit Address</span></p>
                    </div>
                }
                {isEditingAddress &&
                    <EditProfileAddress
                        address1DefaultValue={address1}
                        address2DefaultValue={address2}
                        cityDefaultValue={city}
                        stateDefaultValue={state}
                        zipDefaultValue={zip}
                        countryDefaultValue={country}
                        countries={countries}
                        onUpdateAddress={updateAddressHandler}
                    />
                }
                <div className={classes.row}>
                    <h6>Please complete your personal information. It is not visible to others online, except in the group roster.</h6>
                </div>
                <div className={`${classes.row}`}>
                    <label>Birthday</label>
                    <div className={classes['birthday-dropdown-row']}>
                        <Calendar
                            selectionMode="single"
                            value={birthdayValue}
                            dateFormat="dd-M-yy"
                            yearRange={`${minYear}:${maxYear}`}
                            showIcon={true}
                            icon="pi pi-calendar"
                            monthNavigator={true}
                            yearNavigator={true}
                            showButtonBar={true}
                            onChange={(e) => selectedChangeDateHandler(e.value as Date)}
                            readOnlyInput={true}
                        />
                    </div>
                </div>
                <div className={`${classes.row} ${classes['gender-select-row']}`}>
                    <label htmlFor={genderValue}>Gender</label>
                    <select value={genderValue} onChange={genderChangeHandler}>
                        {genderOptions.map(({ text, value }) => <option value={value} key={value}>{text}</option>)}
                    </select>
                </div>
                {genderValue && genderValue === 'Other' &&
                    <Input
                        inputLabel={"Gender Other"}
                        inputType={"text"}
                        inputRequired={true}
                        inputReadOnly={false}
                        inputValue={genderOtherValue}
                        inputStyle={formHasError && genderOtherHasError ? 'input-textbox-error' : ''}
                        inputOnChange={genderOtherChangeHandler}
                        maxLength={20}
                    />
                }
                <div className={`${classes.row} ${classes['race-ethnicity-select-row']}`}>
                    <label htmlFor={raceEthnicityValue}>Race/Ethnicity</label>
                    <select value={raceEthnicityValue} onChange={raceEthnicityChangeHandler}>
                        {raceEthnicityOptions.map(({ text, value }) => <option value={value} key={value}>{text}</option>)}
                    </select>
                </div>
                {raceEthnicityValue && raceEthnicityValue === 'Other' &&
                    <Input
                        inputLabel={"Race/Ethnicity Other"}
                        inputType={"text"}
                        inputRequired={true}
                        inputReadOnly={false}
                        inputValue={raceEthnicityOtherValue}
                        inputStyle={formHasError && raceEthnicityOtherHasError ? 'input-textbox-error' : ''}
                        inputOnChange={raceEthnicityOtherChangeHandler}
                        maxLength={20}
                    />
                }
                <Input
                    inputLabel="Spouse/Significant Other"
                    inputType="text"
                    inputRequired={false}
                    inputReadOnly={false}
                    inputValue={spouseValue}
                    inputStyle=""
                    inputOnChange={spouseChangeHandler}
                />
                <div className={classes['form-action-buttons-row']}>
                    <BackButton onClickHandler={prevButtonClickHandler}/>
                    <NextButton onClickHandler={nextButtonClickHandler}/>
                </div>
            </FormContent>
        </Fragment>
    )
};

export default CreateAccountStep2;
