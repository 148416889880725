import {FC} from "react";
import classes from "./FormHeader.module.css"

const FormHeader: FC<{ heading?: string, subheading?: string }> = ({heading, subheading }) => {
    return (
        <div className={classes['form-header']}>
            <h4>{heading}</h4>
            {subheading && <h6>{subheading}</h6>}
        </div>
    );
};

export default FormHeader;
