import React, {Context, createContext, FC, useCallback, useState} from "react";
import useHttp from "../hooks/use-http";
import {Person, PersonProperty} from "../model/Person";
import {
    findPersonPropertyValue,
    getPersonUrl
} from "../utils/Constants"
import {Network} from "../model/Network";

type PersonContextObj = {
    person: Person | undefined,
    showBiography: boolean,
    networks: Network[],
    fetchPerson: (token: string | undefined) => void,
    updateStep1Details: (firstName: string, lastName: string, username: string, password: string) => void,
    updateStep2Details: (email: string, updatedPersonProperties: PersonProperty[]) => void,
    updateStep3Details: (updatedPersonProperties: PersonProperty[]) => void,
    updateStep4Details: (updatedNetworks: Network[]) => void,
    isLoading: boolean,
    statusCode: string|null
};

const PersonContext: Context<PersonContextObj> = createContext<PersonContextObj>({
    person: undefined,
    showBiography: false,
    networks: [],
    fetchPerson: (token: string | undefined) => {},
    updateStep1Details: (firstName: string, lastName: string, username: string, password: string) => {},
    updateStep2Details: (email: string, updatedPersonProperties: PersonProperty[]) => {},
    updateStep3Details: (updatedPersonProperties: PersonProperty[]) => {},
    updateStep4Details: (updatedNetworks: Network[]) => {},
    isLoading: false,
    statusCode: ''
});

export const PersonContextProvider: FC = (props) => {
    const [person, setPerson] = useState<Person>();
    const [networks, setNetworks] = useState<Network[]>([]);
    const { isLoading, statusCode, sendRequest: getPerson } = useHttp();
    const [showBiography, setShowBiography] = useState<boolean>(false);
    const updateShowBiography = useCallback((person: Person) => {
        const biography = findPersonPropertyValue(23, person);
        setShowBiography(biography.trim().length === 0);
    }, []);
    const fetchPersonHandler = useCallback((token: string | undefined) => {
        const handleGetPersonResponse = (response: any) => {
            setPerson(response.data);
            updateShowBiography(response.data);
        }
        getPerson({
            url: getPersonUrl(token)
        }, handleGetPersonResponse, () => {});
        return
    }, [getPerson, updateShowBiography]);
    const updateStep1DetailsHandler = useCallback((firstName: string, lastName: string, username: string, password: string) => {
        setPerson({
            account: {
                ...person!.account,
                username: username,
                password: password,
                firstName: firstName,
                lastName: lastName
            },
            properties: [...person!.properties]
        });
    }, [person]);
    const updateStep2DetailsHandler = useCallback((email: string, updatedPersonProperties: PersonProperty[]) => {
        setPerson({
            account: {
                ...person!.account,
                email: email,
            },
            properties: [...updatedPersonProperties]
        });
    }, [person]);
    const updateStep3DetailsHandler = useCallback((updatedPersonProperties: PersonProperty[]) => {
        setPerson({
            account: {...person!.account},
            properties: [...updatedPersonProperties]
        });
    }, [person]);
    const updateStep4DetailsHandler = useCallback((updatedNetworks) => {
        setNetworks([...updatedNetworks]);
    }, []);
    const context = {
        person: person,
        showBiography: showBiography,
        networks: networks,
        fetchPerson: fetchPersonHandler,
        updateStep1Details: updateStep1DetailsHandler,
        updateStep2Details: updateStep2DetailsHandler,
        updateStep3Details: updateStep3DetailsHandler,
        updateStep4Details: updateStep4DetailsHandler,
        isLoading: isLoading,
        statusCode: statusCode
    }
    return (
        <PersonContext.Provider value={context}>
            {props.children}
        </PersonContext.Provider>
    );
};

export default PersonContext;
