import {FC, Fragment, useContext, useEffect} from "react";
import {Navigate, useParams} from "react-router-dom";
import LoadingSpinner from "../components/ui/LoadingSpinner";
import UserTokenContext from "../store/user-token-context";

const TokenValidationGuard: FC<{redirectTo: string}> = ({children, redirectTo}) => {
    const { token } = useParams();
    const {isLoading, getUserToken, error} = useContext(UserTokenContext);
    useEffect(() => {
        getUserToken(token);
    }, [token, getUserToken]);

    if (isLoading) {
        return <LoadingSpinner/>
    } else if (!isLoading && (error || token === undefined)) {
        return <Navigate to={`${redirectTo}?tokenInvalid=true`} />
    }
    return <Fragment>
        {children}
    </Fragment>
};

export default TokenValidationGuard;
