import classes from "./CreateAccount.module.css"
import useInput from "../../hooks/use-input";
import {getStatesForCountryUrl, isNotEmpty, isUSorCA} from "../../utils/Constants";
import {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import Input from "../ui/Input";
import useHttp from "../../hooks/use-http";
import {Country} from "../../model/Country";
import {State} from "../../model/State";
type EditProfileAddressObj = {
    address1DefaultValue: string,
    address2DefaultValue: string,
    cityDefaultValue: string,
    stateDefaultValue: string,
    zipDefaultValue: string,
    countryDefaultValue: string,
    countries: Country[]
    onUpdateAddress: Function
}
const EditProfileAddress: FC<EditProfileAddressObj> = (
    {
        address1DefaultValue,
        address2DefaultValue,
        cityDefaultValue,
        stateDefaultValue,
        zipDefaultValue,
        countryDefaultValue,
        countries,
        onUpdateAddress
    }) => {
    const [states, setStates] = useState<State[]>([]);
    const {sendRequest: getStatesForCountry} = useHttp();
    const [countryValue, setCountryValue] = useState(countryDefaultValue);
    const [stateValue, setStateValue] = useState(stateDefaultValue);
    useEffect(() => {
        if (isUSorCA(countryValue)) {
            const updateStatesForCountry = (response: any) => {
                setStates(response.data.states);
            }
            getStatesForCountry({
                url: getStatesForCountryUrl(countryValue)
            }, updateStatesForCountry);
        } else {
            setStates([]);
        }
    }, [countryValue, getStatesForCountry]);

    const countryChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        setCountryValue(e.target.value);
    }
    const stateChangeHandler= (e: ChangeEvent<HTMLSelectElement>) => {
        setStateValue(e.target.value);
    }
    const {
        value: address1Value,
        hasError: address1HasError,
        valueChangeHandler: address1ChangeHandler
    } = useInput(address1DefaultValue, isNotEmpty);
    const {
        value: address2Value,
        valueChangeHandler: address2ChangeHandler
    } = useInput(address2DefaultValue, () => {
    });
    const {
        value: cityValue,
        hasError: cityHasError,
        valueChangeHandler: cityChangeHandler
    } = useInput(cityDefaultValue, isNotEmpty);
    const {
        value: zipValue,
        hasError: zipHasError,
        valueChangeHandler: zipChangeHandler
    } = useInput(zipDefaultValue, isNotEmpty);

    const [formHasError, setFormHasError] = useState(false);

    const formSubmitHandler = (e: FormEvent) => {
        e.preventDefault();
        setFormHasError(false);
        if (address1HasError || cityHasError || zipHasError || !countryValue) {
            setFormHasError(true);
            return;
        }
        onUpdateAddress(address1Value, address2Value, cityValue, stateValue, zipValue, countryValue);
    }

    return (
        <form onSubmit={formSubmitHandler}>
            <Input
                inputLabel="Address 1"
                inputType="text"
                inputRequired={true}
                inputReadOnly={false}
                inputValue={address1Value}
                inputStyle={formHasError && address1HasError ? 'input-textbox-error' : ''}
                inputOnChange={address1ChangeHandler}
            />
            {formHasError && address1HasError && <p className={classes['error-description']}>Please enter an address</p>}
            <Input
                inputLabel="Address 2"
                inputType="text"
                inputRequired={false}
                inputReadOnly={false}
                inputValue={address2Value}
                inputStyle=""
                inputOnChange={address2ChangeHandler}
            />
            <Input
                inputLabel="City"
                inputType="text"
                inputRequired={true}
                inputReadOnly={false}
                inputValue={cityValue}
                inputStyle={formHasError && cityHasError ? 'input-textbox-error' : ''}
                inputOnChange={cityChangeHandler}
            />
            {formHasError && cityHasError && <p className={classes['error-description']}>Please enter a city</p>}
            {('US' === countryValue || 'CA' === countryValue) &&
                <div className={classes.row}>
                    <label>State</label>
                    <select value={stateValue} onChange={stateChangeHandler}>
                    <option value="">Select...</option> {states.map(({name, stateCode}) =>
                        <option value={stateCode} key={stateCode}>{name}</option>
                    )}
                    </select>
                </div>
            }
            {('US' !== countryValue && 'CA' !== countryValue) &&
                <Input
                    inputLabel="State"
                    inputType="text"
                    inputRequired={false}
                    inputReadOnly={false}
                    inputValue={stateValue}
                    inputStyle=""
                    inputOnChange={(e: ChangeEvent<HTMLInputElement>) => {setStateValue(e.target.value)}}
                />
            }
            <Input
                inputLabel="Zip"
                inputType="text"
                inputRequired={true}
                inputReadOnly={false}
                inputValue={zipValue}
                inputStyle={formHasError && zipHasError ? 'input-textbox-error' : ''}
                inputOnChange={zipChangeHandler}
            />
            {formHasError && zipHasError && <p className={classes['error-description']}>Please enter a zip code</p>}
            <div className={classes.row}>
                <label className={classes.required}>Country</label>
                <select value={countryValue} onChange={countryChangeHandler} className={formHasError && !countryValue ? 'error' : ''}>
                    <option value="">Select...</option>
                    {countries.map(({name, countryCode}) =>
                        <option value={countryCode} key={countryCode}>{name}</option>
                    )}
                </select>
            </div>
            {formHasError && !countryValue && <p className={classes['error-description']}>Please select a country</p>}
            <div className={classes.row}>
                <button className={classes.button} type="submit">Update address</button>
            </div>
        </form>
    );
};

export default EditProfileAddress;
