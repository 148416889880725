import React, {Context, createContext, FC, useCallback, useState} from "react";

type AppConfig = {
    privacyPolicyUrl: string,
    termsOfUseUrl: string,
    myVistageUrl: string,
    thankYouVideoUrl: string,
    confidentialityPledgeUrl: string,
    liveChatUrl: string,
    birthdayMaxYear: number,
    birthdayMinYear: number,
}
type AppContextObj = {
    config: AppConfig | undefined,
    setAppContext: () => void
}
const AppContext: Context<AppContextObj> = createContext<AppContextObj>({
    config: undefined,
    setAppContext: () => {}
});

export const AppContextProvider: FC = (props) => {
    const [config, setConfig] = useState<AppConfig>();
    const setAppContextHandler = useCallback(async () => {
        const response = await fetch(window.location.origin + '/config.json');
        const data = await response.json();
        setTimeout(() => {
            setConfig(data);
        });
    }, []);
    const context = {
        config: config,
        setAppContext: setAppContextHandler
    }
    return (
        <AppContext.Provider value={context}>
            {props.children}
        </AppContext.Provider>
    );
}

export default AppContext;
