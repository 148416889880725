import {FC} from "react";
import Header from "./Header";
import Footer from "./Footer";
import Card from "../ui/Card";
import Content from "./Content";
import classes from "./Container.module.css"

const Container: FC = () => {
    return (
        <div className={classes['layout-container']}>
            <div className={classes['layout-container-content']}>
                <Card styles="container-card overflow-auto">
                    <Header/>
                    <Content/>
                </Card>
            </div>
            <Footer/>
        </div>
    );
};

export default Container;
