import {Person, PersonProperty} from "../model/Person";

const basePath: string = '';
const usernameRegex: RegExp = /^(?!.*\.\.)(?!.*\.$)[^\W][\w+@.-]{5,100}$/i;
const passwordRegex: RegExp = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z])(?!.*[#^&*()_\-={}[\]|\\:;'"<>,.?/~`]).*$/;
const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

export const genderOptions = [
    { text: 'Select...', value: '' },
    { text: 'Female', value: 'Female' },
    { text: 'Male', value: 'Male' },
    { text: 'Prefer not to specify', value: 'Prefer not to specify'},
    { text: 'Other', value: 'Other' },
];

export const raceEthnicityOptions = [
    { text: 'Select...', value: '' },
    { text: 'Asian', value: 'Asian'},
    { text: 'Black or African American', value: 'Black or African American'},
    { text: 'Hispanic or Latino', value: 'Hispanic or Latino'},
    { text: 'Native American or Alaskan Native', value: 'Native American or Alaskan Native'},
    { text: 'Native Hawaiian or other Pacific Islander', value: 'Native Hawaiian or other Pacific Islander'},
    { text: 'White or Caucasian', value: 'White or Caucasian'},
    { text: 'Multiracial or Biracial', value: 'Multiracial or Biracial'},
    { text: 'Prefer not to specify', value: 'Prefer not to specify'},
    { text: 'Other', value: 'Other'}
];

export const organizationTypesOptions = [
    { text: 'Select...', value: '' },
    { text: 'Government', value: 'Government' },
    { text: 'Not-for-profit', value: 'Not-for-profit' },
    { text: 'Private', value: 'Private' },
    { text: 'Public', value: 'Public'},
    { text: 'Family-owned', value: 'Family-owned' }
];

export const annualRevenueOptions = [
    { text: 'Select...', value: '' },
    { text: 'Less than 500k', value: 'Less than 500k' },
    { text: '500-999k', value: '500-999k' },
    { text: '1-4 Million', value: '1-4 Million' },
    { text: '5-9 Million', value: '5-9 Million' },
    { text: '10-20 Million', value: '10-20 Million' },
    { text: '21-49 Million', value: '21-49 Million' },
    { text: '50-99 Million', value: '50-99 Million' },
    { text: '100-249 Million', value: '100-249 Million' },
    { text: '250-499 Million', value: '250-499 Million' },
    { text: '500-999 Million', value: '500-999 Million' },
    { text: '1+ Billion', value: '1+ Billion' }
];

export const numberOfEmployeesOptions = [
    { text: 'Select...', value: '' },
    { text: '0', value: '0' },
    { text: '1-9', value: '1-9' },
    { text: '10-19', value: '10-19' },
    { text: '20-49', value: '20-49' },
    { text: '50-99', value: '50-99' },
    { text: '100-499', value: '100-499' },
    { text: '500-999', value: '500-999' },
    { text: '1000-4999', value: '1000-4999' },
    { text: '5000-9999', value: '5000-9999' },
    { text: '10000+', value: '10000+' }
];
export const expiredTokenUrl = `${basePath}/account/expiredToken`;
export const findPersonPropertyValue = (fieldId: number, person?: Person): string => {
    if (!person) {
        return '';
    }
    return person.properties.filter(prop => prop.fieldId === fieldId)[0]?.value || ''
};

export const getPersonUrl = (token: string | undefined): string => {
    return `${basePath}/account/person?token=${token}`;
};
export const validateUsernameUrl = (userId: number | undefined): string => {
    return `${basePath}/usernameInUse?vistageId=${userId}`;
}
export const getCountriesUrl: string = `${basePath}/geo/countries`;
export const getStatesForCountryUrl = (countryCode: string): string => {
    return `${basePath}/geo/country/${countryCode}/states`;
};
export const getIndustriesUrl: string = `${basePath}/account/industries`;
export const getForgotUsernameUrl: string = `${basePath}/forgotUsername`;
export const getForgotPasswordUrl: string = `${basePath}/forgotPassword`;
export const validateTokenUrl: string = `${basePath}/validateAccountToken`;
export const resetUsernameUrl: string = `${basePath}/resetUsername`;
export const resetPasswordUrl: string = `${basePath}/resetPassword`;
export const getNetworksUrl: string = `${basePath}/networks/`;
export const recordTermsAcceptanceUrl = (userId: number | undefined) => {
    return `${basePath}/account/person/terms?personVistageId=${userId}`;
}
export const getSubmitCreateAccountFormUrl: string = `${basePath}/account/person`;
export const getAccountAutoLoginUrl = (token: string | undefined) => {
    return `${basePath}/account/login?token=${token}`;
}

export const isNotEmpty = (value: string): boolean => {
    return value.trim().length > 0;
};
export const isUsernameValid = (value: string): boolean => {
    return isNotEmpty(value) && usernameRegex.test(value);
};
export const isPasswordValid = (value: string): boolean => {
    return isNotEmpty(value) && passwordRegex.test(value);
};
export const isEmailValid = (value: string): boolean => {
    return isNotEmpty(value) && emailRegex.test(value);
};
export const isUSorCA = (value: string): boolean => {
    return isNotEmpty(value) && ('US' === value || 'CA' === value);
};
export const findPropertyByFieldId = (fieldId: number, properties: PersonProperty[]): any => {
    let i = 0;
    let found = false;
    let ret: null | number = null;
    while (!found && i < properties.length) {
        if (properties[i].fieldId === fieldId) {
            ret = i;
            found = true;
        }
        i++;
    }
    return ret;
};
export const findPersonPropertyByFieldId = (fieldId: number, person: Person): any => {
    return findPropertyByFieldId(fieldId, person.properties);
};
export const updatePersonProperty = (person: Person, propertiesCopy: PersonProperty[], id: number, newValue: any) => {
    const personPropertyIndex = findPersonPropertyByFieldId(id, person!);
    personPropertyIndex !== null
        ? propertiesCopy[findPersonPropertyByFieldId(id, person!)].value = newValue
        : propertiesCopy.push({fieldId: id, value: newValue});
}
