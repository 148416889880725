import {FC, useState} from "react";
import CreateAccountStep1 from "./CreateAccountStep1";
import CreateAccountStep2 from "./CreateAccountStep2";
import CreateAccountStep3 from "./CreateAccountStep3";
import CreateAccountStep4 from "./CreateAccountStep4";
import classes from "./CreateAccount.module.css"
import CreateAccountStep5 from "./CreateAccountStep5";

const CreateAccount: FC = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const scrollToTop = () => {
        document.getElementById('app-header')?.scrollIntoView({behavior: 'smooth'});
    }
    const goToNextPageHandler = () => {
        setCurrentStep(prevState => prevState + 1);
        scrollToTop();
    }
    const goToPreviousPageHandler = () => {
        setCurrentStep(prevState => prevState - 1);
        scrollToTop();
    }
    return (
        <div className={classes['create-account']}>
            {currentStep === 1 && <CreateAccountStep1 goToNextPage={goToNextPageHandler}/>}
            {currentStep === 2 &&
                <CreateAccountStep2 goToNextPage={goToNextPageHandler} goToPreviousPage={goToPreviousPageHandler}/>}
            {currentStep === 3 &&
                <CreateAccountStep3 goToNextPage={goToNextPageHandler} goToPreviousPage={goToPreviousPageHandler}/>}
            {currentStep === 4 &&
                <CreateAccountStep4 goToNextPage={goToNextPageHandler} goToPreviousPage={goToPreviousPageHandler}/>}
            {currentStep === 5 &&
                <CreateAccountStep5 goToPreviousPage={goToPreviousPageHandler}/>}
        </div>
    );
};

export default CreateAccount;
