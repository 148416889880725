import React, {FC} from "react";
import Modal from "./Modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from "./IFrame.module.css";
import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";


const IFrame: FC<{ heading: string, url: string|undefined, onClose: any }> = ({heading, url, onClose}) => {
    return (
        <Modal>
            <div className={classes.iframe}>
                <div className={classes['iframe-header']}>
                    <div className={classes['iframe-header-title']}>
                        <h4>{heading}</h4>
                    </div>
                    <div className={classes['iframe-header-close-button']}>
                        <FontAwesomeIcon icon={faCircleXmark} onClick={onClose} size={"2x"}/>
                    </div>
                </div>
                <div className={classes['iframe-content']}>
                    <iframe src={url} className={classes['iframe-body']} title={heading}/>
                </div>
            </div>
        </Modal>
    );
};

export default IFrame;
