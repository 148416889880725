import {FC, FormEvent, Fragment, useContext, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useInput from "../hooks/use-input";
import useHttp from "../hooks/use-http";
import FormHeader from "./layout/FormHeader";
import FormContent from "./layout/FormContent";
import Input from "./ui/Input";
import LoadingSpinner from "./ui/LoadingSpinner";
import SupportHelpText from "./SupportHelpText";
import {isNotEmpty, isPasswordValid, resetPasswordUrl} from "../utils/Constants";
import classes from "./Forms.module.css";
import AppContext from "../store/app-context";

const INVALID_TOKEN_CODE = 403;
const INVALID_USERNAME_CODE = 400;

const ResetPassword: FC = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const { config } = useContext(AppContext);
    const [formHasError, setFormHasError] = useState(false);
    const [formError, setFormError] = useState('');
    const [showPasswordChecked, setShowPasswordChecked] = useState(false);
    const redirectUrl = `${config?.myVistageUrl}/?passwordchanged=true`;
    const fallbackRedirectUrl = `${config?.myVistageUrl}/?passwordchanged=false`;
    const isConfirmPasswordValid = (value: string): boolean => {
        if (passwordValue) {
            return isNotEmpty(value) && value === passwordValue
        }
        return isNotEmpty(value) && isPasswordValid(value);
    }
    const {
        value: passwordValue,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler
    } = useInput('', isPasswordValid);
    const {
        value: confirmPasswordValue,
        hasError: confirmPasswordHasError,
        valueChangeHandler: confirmPasswordChangeHandler
    } = useInput('', isConfirmPasswordValid);
    const {
        isLoading: httpIsLoading,
        sendRequest: httpSendRequest
    } = useHttp();
    const showPasswordChangeHandler = () => {
        setShowPasswordChecked(prevState => !prevState);
    }
    const scrollToTop = () => {
        document.getElementById('app-header')?.scrollIntoView({behavior: 'smooth'});
    }
    const setFormErrorValues = (error: string) => {
        setFormHasError(true);
        setFormError(error);
        scrollToTop();
    }
    const submitHandler = (e: FormEvent) => {
        e.preventDefault();
        setFormError('');
        if (passwordHasError || confirmPasswordHasError) {
            setFormErrorValues('Please fill in the required field(s)');
            return;
        }
        httpSendRequest({
            url: resetPasswordUrl,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: {
                'token': token,
                'newPassword': passwordValue
            }
        }, () => {
            window.location.href = redirectUrl;
        }, (error: any) => {
            switch (+error.statusCode) {
                case INVALID_USERNAME_CODE:
                    window.location.href = fallbackRedirectUrl;
                    return;
                case INVALID_TOKEN_CODE:
                default:
                    navigate('/forgot-password?tokenInvalid=true');
                    return;
            }
        });
    }
    return (
        <Fragment>
            {httpIsLoading && <LoadingSpinner />}
            <div className={classes['reset-password']}>
                <FormHeader heading={"Reset Password"} />
                <FormContent>
                    <form onSubmit={submitHandler}>
                        {formHasError && <p className={classes['form-error-description']}>{formError}</p>}
                        <div className={classes.row}>
                            Passwords are case-sensitive and must meet this criteria:
                            <ul>
                                <li>Passwords are case-sensitive and must be 8+ character</li>
                                <li>You may use letters, numbers, and symbols @ ! $ + %</li>
                                <li>You must include at least 1 uppercase letter, 1 lowercase letter and 1 number</li>
                            </ul>
                        </div>
                        <Input
                            inputLabel={"New password"}
                            inputType={showPasswordChecked ? "text" : "password"}
                            inputRequired={true}
                            inputReadOnly={false}
                            inputValue={passwordValue}
                            inputStyle={formHasError && passwordHasError ? 'input-textbox-error' : ''}
                            inputOnChange={passwordChangeHandler}
                        />
                        {formHasError && passwordHasError &&
                            <p className={classes['error-description']}>Password must meet the above criteria</p>
                        }
                        <Input
                            inputLabel={"Verify new password"}
                            inputType={showPasswordChecked ? "text" : "password"}
                            inputRequired={true}
                            inputReadOnly={false}
                            inputValue={confirmPasswordValue}
                            inputStyle={formHasError && confirmPasswordHasError ? 'input-textbox-error' : ''}
                            inputOnChange={confirmPasswordChangeHandler}
                        />
                        {formHasError && confirmPasswordHasError &&
                            <p className={classes['error-description']}>Password and Verify values must match</p>
                        }
                        <div className={classes['show-password-row']}>
                            <input type="checkbox" onChange={showPasswordChangeHandler}/>
                            <label>Show Password</label>
                        </div>
                        <div className={`${classes.row} ${classes['form-action-button']}`}>
                            <button type={"submit"} className={classes['submit-button']}>
                                Set Password
                            </button>
                        </div>
                        <SupportHelpText />
                    </form>
                </FormContent>
            </div>
        </Fragment>
    );
};

export default ResetPassword;
