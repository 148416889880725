import {FC, FormEvent, Fragment, useContext, useState} from "react";
import {useSearchParams} from "react-router-dom";
import useHttp from "../hooks/use-http";
import Input from "./ui/Input";
import FormHeader from "./layout/FormHeader";
import FormContent from "./layout/FormContent";
import useInput from "../hooks/use-input";
import LoadingSpinner from "./ui/LoadingSpinner";
import {getForgotUsernameUrl, isEmailValid} from "../utils/Constants";
import classes from "./Forms.module.css";
import SupportHelpText from "./SupportHelpText";
import AppContext from "../store/app-context";

const ForgotUsername: FC = () => {
    const [tokenInvalidParam] = useSearchParams();
    const { config } = useContext(AppContext);
    const isTokenInvalid: boolean = Boolean(tokenInvalidParam.get('tokenInvalid') === 'true');
    const [formHasError, setFormHasError] = useState(false);
    const [formError, setFormError] = useState('');
    const redirectUrl = `${config?.myVistageUrl}/?usernamechangerequest=true`;
    const {
        value: emailValue,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler
    } = useInput('', isEmailValid);
    const {
        isLoading: sendingForgotUsernameRequest,
        sendRequest: sendForgotUsernameRequest,
        error: sendForgotUsernameRequestHasError
    } = useHttp();
    const scrollToTop = () => {
        document.getElementById('app-header')?.scrollIntoView({behavior: 'smooth'});
    }
    const submitHandler = (e: FormEvent) => {
        e.preventDefault();
        setFormError('');
        if (emailHasError) {
            setFormError('Please complete the following required field(s).')
            setFormHasError(true);
            scrollToTop();
            return;
        }
        sendForgotUsernameRequest({
            url: getForgotUsernameUrl,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: { "email": emailValue }
        }, () => {
            window.location.href = redirectUrl;
        }, () => {});

    }
    return (
        <Fragment>
            {sendingForgotUsernameRequest && <LoadingSpinner />}
            <div className={classes['forgot-username']}>
                <FormHeader heading="Forgot Username" />
                <FormContent>
                    <form onSubmit={submitHandler}>
                        {isTokenInvalid &&
                            <div className={`${classes.row} ${classes['form-error-description']}`}>
                                The link to change your username has expired. Please enter your email address below and we'll email you a new link that will be valid for 30 days.
                            </div>
                        }
                        {sendForgotUsernameRequestHasError &&
                            <p className={classes['form-error-description']}>
                                An unexpected error occurred. Please try again in a few minutes.
                            </p>
                        }
                        {formHasError && <p className={classes['form-error-description']}>{formError}</p>}
                        <div className={classes.row}>
                            Please enter the email address you provided to Vistage and we'll email you your username and a link to optionally reset your username.
                        </div>
                        <Input
                            inputLabel="Email address"
                            inputType="text"
                            inputRequired={true}
                            inputReadOnly={false}
                            inputValue={emailValue}
                            inputStyle={formHasError && emailHasError ? 'input-textbox-error' : ''}
                            inputOnChange={emailChangeHandler}
                            maxLength={100}
                        />
                        <div className={`${classes.row} ${classes['form-action-button']}`}>
                            <button type={"submit"} className={classes['submit-button']}>
                                Send
                            </button>
                        </div>
                        <SupportHelpText />
                    </form>
                </FormContent>
            </div>
        </Fragment>
    );
};

export default ForgotUsername;
