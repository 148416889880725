import {FC} from "react";
import classes from './Header.module.css';
import myVistageLogo from '../../assets/my-vistage.png';

const Header: FC = () => {
    return (
        <div className={classes.header} id="app-header">
            <img className={classes['header-img-logo']} src={myVistageLogo} alt="My Vistage" />
        </div>
    );
};

export default Header;
