import React, {FC, Fragment, useContext, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import FormHeader from "../layout/FormHeader";
import FormContent from "../layout/FormContent";
import BackButton from "../ui/BackButton";
import IFrameLink from "../ui/IFrameLink";
import SupportHelpText from "../SupportHelpText";
import LoadingSpinner from "../ui/LoadingSpinner";
import useHttp from "../../hooks/use-http";
import PersonContext from "../../store/person-context";
import {PersonProperty} from "../../model/Person";
import classes from "./CreateAccount.module.css";
import {
    findPropertyByFieldId,
    getSubmitCreateAccountFormUrl,
    isUSorCA,
    recordTermsAcceptanceUrl
} from "../../utils/Constants";
import AppContext from "../../store/app-context";

const CreateAccountStep5: FC<{goToPreviousPage: Function}> = ({goToPreviousPage}) => {
    const {token} = useParams();
    const { config } = useContext(AppContext);
    const navigate = useNavigate();
    const {person, networks} = useContext(PersonContext);
    const {sendRequest} = useHttp();
    const [isLoading, setIsLoading] = useState(false);
    const [formHasError, setFormHasError] = useState(false);
    const [formError, setFormError] = useState('');
    const privacyPolicyUrl: string = `${config?.privacyPolicyUrl}`;
    const termsOfUseUrl: string = `${config?.termsOfUseUrl}`;
    const confidentialityPledgeUrl = `${config?.confidentialityPledgeUrl}`;
    const prevButtonClickHandler = () => {
        goToPreviousPage();
    }
    const scrollToTop = () => {
        document.getElementById('app-header')?.scrollIntoView({behavior: 'smooth'});
    }
    const setPersonProperties = (personProperties: PersonProperty[] | undefined): PersonProperty[] => {
        if (personProperties === undefined) { return []; }
        let properties: PersonProperty[] = [];
        personProperties.forEach(property => {
            properties.push({
                ...property,
                value: property.value
            });
        });
        const country = properties[findPropertyByFieldId(13, properties)].value;
        if (isUSorCA(country)) {
            findPropertyByFieldId(93, properties) !== null
                ? properties[findPropertyByFieldId(93, properties)].value = ''
                : properties.push({fieldId: 93, value: ''});
        } else {
            const state = properties[findPropertyByFieldId(11, properties)].value;
            findPropertyByFieldId(93, properties) !== null
                ? properties[findPropertyByFieldId(93, properties)].value = state
                : properties.push({fieldId: 93, value: state});
            findPropertyByFieldId(11, properties) !== ''
                ? properties[findPropertyByFieldId(11, properties)].value = ''
                : properties.push({fieldId: 11, value: ''});
        }
        if (findPropertyByFieldId(91, properties) !== null) {
            // if a birthdate-value exists, format it to YYYY-MM-DD format else not send that property at all
            const birthDate = properties[findPropertyByFieldId(91, properties)].value;
            if (birthDate != null) {
                properties[findPropertyByFieldId(91, properties)].value = moment(birthDate).format('YYYY-MM-DD');
            } else {
                properties.splice(findPropertyByFieldId(91, properties), 1);
            }
        }
        return properties;
    }
    const submitCreateAccountForm = () => {
        const account = person?.account;
        const properties = setPersonProperties(person?.properties);
        sendRequest({
            url: getSubmitCreateAccountFormUrl,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: {
                'token': token,
                'account': account,
                'properties': properties,
                'networks': networks,
            }
        }, () => {
            setTimeout(() => {
                setIsLoading(false);
                navigate(`/thank-you/${token}`);
            });
        }, () => {
            setFormHasError(true);
            setFormError('Form submission is temporarily unavailable. Please try again in a few minutes.');
            setIsLoading(false);
            scrollToTop();
        });
    }
    const submitHandler = () => {
        setIsLoading(true);
        setFormError('');
        sendRequest({
            url: recordTermsAcceptanceUrl(person?.account.personVistageId),
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: {
                'termsFor': 'CreateAccount'
            }
        }, () => {
            submitCreateAccountForm();
        }, () => {
            setFormHasError(true);
            setFormError('Form submission is temporarily unavailable. Please try again in a few minutes.');
            setIsLoading(false);
            scrollToTop();
        });
    }
    return (
        <Fragment>
            {isLoading && <LoadingSpinner/>}
            <FormHeader heading={"Privacy and Terms"}/>
            <FormContent>
                {formHasError && <p className={classes['form-error-description']}>{formError}</p>}
                <div className={classes.row}>
                    By choosing "Save and Agree" below:
                </div>
                <div className={classes.row}>
                    <ol>
                        <li>You agree to Vistage's &nbsp;
                            <IFrameLink text={"Terms of Use"} url={termsOfUseUrl} />.
                        </li>
                        <li>You agree to the Vistage &nbsp;
                            <IFrameLink text={"Privacy Policy"} url={privacyPolicyUrl} />
                            , which describes how we process your information to give you the best possible experience.
                        </li>
                        <li>
                            You agree to abide by the duties and obligations set out in Vistage's &nbsp;
                            <IFrameLink text={"Confidentiality Pledge and Standards"} url={confidentialityPledgeUrl} />.
                        </li>
                    </ol>
                </div>
                <SupportHelpText/>
                <div className={classes['form-action-buttons-row']}>
                    <BackButton onClickHandler={prevButtonClickHandler}/>
                    <button className={classes['save-and-agree']} onClick={submitHandler}>Save and Agree</button>
                </div>
            </FormContent>
        </Fragment>
    );
};

export default CreateAccountStep5;
