import {ChangeEventHandler, FC} from "react";
import classes from "./Input.module.css";

type InputObj = {
    inputLabel: string;
    inputType: string;
    inputRequired: boolean;
    inputReadOnly: boolean;
    inputValue: string;
    inputStyle: string;
    inputOnChange: ChangeEventHandler;
    maxLength?: number
};

const Input: FC<InputObj> = ({inputLabel, inputType, inputRequired, inputReadOnly, inputValue, inputStyle, inputOnChange, ...props}) => {
    return (
        <div className={classes['input-row']}>
            <label
                className={`${classes['input-label']} ${inputRequired ? classes['input-required'] : ''}`}
                htmlFor={inputLabel}
            >
                {inputLabel}
            </label>
            <input
                className={`${classes['input-textbox']} ${inputStyle ? classes[inputStyle] : ''}`}
                type={inputType}
                value={inputValue}
                readOnly={inputReadOnly}
                onChange={inputOnChange}
                {...props}
            />
        </div>
    );
};

export default Input;
