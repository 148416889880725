import {ChangeEvent, FC} from "react";
import classes from "./CreateAccount.module.css"
import {Network} from "../../model/Network";
import {Chip} from "primereact/chip";

type NetworksDropDownObj = {
    industryLabel: string,
    category: string,
    networks: Network[],
    selectedNetworks: Network[],
    onSelect: (e: ChangeEvent<HTMLSelectElement>) => void,
    onRemove: (id: number) => void,
    canSelect: (id: number) => boolean
}

const NetworksDropDown: FC<NetworksDropDownObj> = ({industryLabel, category, networks, selectedNetworks, onSelect, onRemove, canSelect}) => {
    const networkSelectHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        onSelect(e);
    }
    const removeNetworkHandler = (id: number) => {
        onRemove(id);
    }
    const canSelectNetwork = (id: number): boolean => {
        return canSelect(id);
    }
    return (
        <div className={classes.row}>
            <label>{industryLabel}</label>
            <select onChange={networkSelectHandler} value={""}>
                <option value="">Select...</option>
                {networks.map((network) =>
                    <option value={network.id} key={network.id} disabled={canSelectNetwork(network.id)}>{network.name}</option>
                )}
            </select>
            <div>
                {selectedNetworks
                    .sort((n1,n2) => n1.name > n2.name ? 1 : -1)
                    .map((network) =>
                        network.category === category &&
                        <Chip
                            label={network.name}
                            removable={true}
                            onRemove={removeNetworkHandler.bind(this, network.id)}
                            key={network.id}
                        />
                    )
                }
            </div>
        </div>
    );
};

export default NetworksDropDown;
