import {FC, FormEvent, Fragment, useContext, useState} from "react";
import {useSearchParams} from "react-router-dom";
import useHttp from "../hooks/use-http";
import useInput from "../hooks/use-input";
import FormHeader from "./layout/FormHeader";
import FormContent from "./layout/FormContent";
import Input from "./ui/Input";
import LoadingSpinner from "./ui/LoadingSpinner";
import {getForgotPasswordUrl, isNotEmpty} from "../utils/Constants";
import classes from "./Forms.module.css"
import SupportHelpText from "./SupportHelpText";
import AppContext from "../store/app-context";

const ForgotPassword: FC = () => {
    const [tokenInvalidParam] = useSearchParams();
    const { config } = useContext(AppContext);
    const isTokenInvalid: boolean = Boolean(tokenInvalidParam.get('tokenInvalid') === 'true');
    const [formHasError, setFormHasError] = useState(false);
    const [formError, setFormError] = useState('');
    const redirectUrl = `${config?.myVistageUrl}/?passwordchangerequest=true`;
    const {
        value: usernameValue,
        hasError: usernameHasError,
        valueChangeHandler: usernameChangeHandler
    } = useInput('', isNotEmpty);
    const {
        isLoading: sendingForgotPasswordRequest,
        sendRequest: sendForgotPasswordRequest,
        error: sendForgotPasswordRequestHasError
    } = useHttp();
    const scrollToTop = () => {
        document.getElementById('app-header')?.scrollIntoView({behavior: 'smooth'});
    }
    const submitHandler = (e: FormEvent) => {
        e.preventDefault();
        setFormError('');
        if (usernameHasError) {
            setFormError('Please complete the following required field(s).')
            setFormHasError(true);
            scrollToTop();
            return;
        }
        sendForgotPasswordRequest({
            url: getForgotPasswordUrl,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "identifier": usernameValue
            }
        }, () => {
            window.location.href = redirectUrl;
        }, () => {});
    }
    return (
        <Fragment>
            {sendingForgotPasswordRequest && <LoadingSpinner />}
            <div className={classes['forgot-password']}>
                <FormHeader heading="Forgot Password" />
                <FormContent>
                    <form onSubmit={submitHandler}>
                        {isTokenInvalid &&
                            <div className={`${classes.row} ${classes['form-error-description']}`}>
                                The link to change your password has expired. Please enter your email address below and we'll email you a new link that will be valid for 30 days.
                            </div>
                        }
                        {sendForgotPasswordRequestHasError &&
                            <p className={classes['form-error-description']}>
                                An unexpected error occurred. Please try again in a few minutes.
                            </p>
                        }
                        {formHasError && <p className={classes['form-error-description']}>{formError}</p>}
                        <div className={classes.row}>
                            Please enter the username or email address you provided to Vistage and we'll email you a link to reset your password.
                        </div>
                        <Input
                            inputLabel={"Username or email address"}
                            inputType={"text"}
                            inputRequired={true}
                            inputReadOnly={false}
                            inputValue={usernameValue}
                            inputStyle={formHasError && usernameHasError ? 'input-textbox-error' : ''}
                            inputOnChange={usernameChangeHandler}
                            maxLength={150}
                        />
                        <div className={`${classes.row} ${classes['form-action-button']}`}>
                            <button type={"submit"} className={classes['submit-button']}>
                                Send
                            </button>
                        </div>
                        <SupportHelpText />
                    </form>
                </FormContent>
            </div>
        </Fragment>
    );
};

export default ForgotPassword
