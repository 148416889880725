import {ChangeEvent, useState} from "react";

const useInput = (defaultValue: string, validateValue: Function) => {
    const [enteredValue, setEnteredValue] = useState<string>(defaultValue);
    const valueIsValid = validateValue(enteredValue);
    const hasError = !valueIsValid;

    const valueChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setEnteredValue(event.target.value);
    }

    return {
        value: enteredValue,
        isValid: valueIsValid,
        hasError: hasError,
        valueChangeHandler
    };
};
export default useInput;
