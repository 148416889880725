import {FC, Fragment, useContext, useEffect} from "react";
import {Navigate, useParams} from "react-router-dom";
import LoadingSpinner from "../components/ui/LoadingSpinner";
import PersonContext from "../store/person-context";

const EXPIRED_TOKEN_ERROR_CODE: number = 409;
const MAESTRO_ERROR_CODE_MIN: number = 400;
const MAESTRO_ERROR_CODE_MAX: number = 599;

const CreateAccountGuard: FC = ({children}) => {
    const { token } = useParams();
    const {fetchPerson, isLoading, statusCode} = useContext(PersonContext);

    useEffect(() => {
       fetchPerson(token)
    }, [fetchPerson, token]);

    if (isLoading) {
        return <LoadingSpinner/>
    } else if (!isLoading && statusCode && +statusCode === EXPIRED_TOKEN_ERROR_CODE) {
        return <Navigate to={`/expired-link/${token}`}/>
    } else if (!isLoading && statusCode && +statusCode >= MAESTRO_ERROR_CODE_MIN && +statusCode <= MAESTRO_ERROR_CODE_MAX ) {
        return <Navigate to="/invalid-link"/>
    } else {
        return <Fragment>
            {children}
        </Fragment>
    }
};

export default CreateAccountGuard;
