import {FC, Fragment} from "react";
import FormHeader from "./layout/FormHeader";
import FormContent from "./layout/FormContent";
import SupportHelpText from "./SupportHelpText";

const InvalidLink: FC = () => {
    return (
        <Fragment>
            <FormHeader heading="Invalid Link" />
            <FormContent>
                <p>The link to create your account is not valid.</p>
                <SupportHelpText/>
            </FormContent>
        </Fragment>
    );
};

export default InvalidLink;
