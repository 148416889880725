import {FC} from "react";
import classes from "./FormContent.module.css"

const FormContent: FC = ({children}) => {
    return (
        <div className={classes['form-content']}>
            {children}
        </div>
    );
};

export default FormContent;
