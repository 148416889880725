import React, {FC, Fragment, MouseEvent, MouseEventHandler, useState} from "react";
import IFrame from "./IFrame";

const IFrameLink: FC<{ text: string, url: string|undefined }> = ({text, url }) => {
    const [showIFrame, setShowIFrame] = useState(false);
    const handleClick: MouseEventHandler = (e: MouseEvent) => {
        e.preventDefault();
        setShowIFrame(true);
    }
    const handleClose: MouseEventHandler = () => {
        setShowIFrame(false);
    };
    return (
        <Fragment>
            <a href={url} onClick={handleClick}>{text}</a>
            {showIFrame && <IFrame heading={text} url={url} onClose={handleClose}/>}
        </Fragment>
    );
};

export default IFrameLink;
