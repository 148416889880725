import {FC, MouseEventHandler} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classes from "./BackButton.module.css";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";

const BackButton: FC<{onClickHandler: MouseEventHandler}> = ({onClickHandler}) => {
    return (
        <button className={classes['back-button']} onClick={onClickHandler}>
            <FontAwesomeIcon icon={faAngleLeft}/>
            <span>Back</span>
        </button>
    );
};

export default BackButton;
