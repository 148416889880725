import {FC, Fragment, useCallback, useState} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import PageUnavailable from "../PageUnavailable";
import CreateAccount from "../create-account/CreateAccount";
import CreateAccountGuard from "../../guards/create-account-guard";
import ExpiredLink from "../ExpiredLink";
import InvalidLink from "../InvalidLink";
import {PersonContextProvider} from "../../store/person-context";
import {UserTokenContextProvider} from "../../store/user-token-context";
import ForgotUsername from "../ForgotUsername";
import TokenValidationGuard from "../../guards/token-validation-guard";
import ResetUsername from "../ResetUsername";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";
import ThankYou from "../ThankYou";
import NavigateToCreateAccount from "../NavigateToCreateAccount";
import classes from "./Content.module.css";

const Content: FC = () => {
    const [personVistageId, setPersonVistageId] = useState<number>();
    useCallback((vistageId: number) => {
        if (personVistageId && personVistageId !== vistageId) {
            setPersonVistageId(vistageId);
        }
    }, [personVistageId]);
    return (
        <div className={classes.content}>
            <Routes>
                <Route path="/expired-link/:token" element={<ExpiredLink />} />
                <Route path="/invalid-link" element={<InvalidLink />} />
                <Route path="/forgot-username" element={<ForgotUsername />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/page-unavailable" element={<PageUnavailable />} />
                <Route path="/thank-you/:token" element={<ThankYou />} />

                {/*
                    Moving forward, we want to avoid exposing vistage id in urls. For welcome emails already sent out,
                    we want to redirect the users to a new url which includes the token only
                */}
                <Route path="/create-account/:userId/:token" element={<NavigateToCreateAccount/>} />

                <Route path="/create-account/:token" element={
                    <PersonContextProvider>
                        <CreateAccountGuard>
                            <CreateAccount />
                        </CreateAccountGuard>
                    </PersonContextProvider> } />
                <Route path="*" element={
                    <Fragment>
                        <Navigate to="/page-unavailable"/>
                    </Fragment>
                } />
                <Route path="/reset-username/:token" element={
                    <UserTokenContextProvider>
                        <TokenValidationGuard redirectTo={"/forgot-username"}>
                            <ResetUsername />
                        </TokenValidationGuard>
                    </UserTokenContextProvider>
                } />
                <Route path="/reset-password/:token" element={
                    <UserTokenContextProvider>
                        <TokenValidationGuard redirectTo={"/forgot-password"}>
                            <ResetPassword />
                        </TokenValidationGuard>
                    </UserTokenContextProvider>
                } />
            </Routes>
        </div>
    );
};

export default Content;
