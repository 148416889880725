import {FC, Fragment, useContext, useState} from "react";
import PersonContext from "../../store/person-context";
import FormHeader from "../layout/FormHeader";
import FormContent from "../layout/FormContent";
import useInput from "../../hooks/use-input";
import Input from "../ui/Input";
import NextButton from "../ui/NextButton";
import useHttp from "../../hooks/use-http";
import classes from "./CreateAccount.module.css"
import {validateUsernameUrl, isNotEmpty, isPasswordValid, isUsernameValid} from "../../utils/Constants";

const CreateAccountStep1:FC<{ goToNextPage: Function }> = ({ goToNextPage }) => {
    const {person, updateStep1Details} = useContext(PersonContext);

    const firstNameDefaultValue = person?.account.firstName || '';
    const lastNameDefaultValue = person?.account.lastName || '';
    const usernameDefaultValue = person?.account.username || '';
    const passwordDefaultValue = person?.account.password || '';
    const confirmPasswordDefaultValue = person?.account.password || '';
    const isFederated = person?.account.isFederated || false;

    const [showPasswordChecked, setShowPasswordChecked] = useState(false);
    const [formHasError, setFormHasErrors] = useState(false);
    const [formError, setFormError] = useState('');

    const {
        error: validatingUsernameHasError,
        sendRequest: validateUsernameRequest
    } = useHttp();
    const isConfirmPasswordValid = (value: string): boolean => {
        if (passwordValue) {
            return isNotEmpty(value) && value === passwordValue;
        }
        return isNotEmpty(value) && isPasswordValid(value);
    };

    const {
        value: firstNameValue,
        hasError: firstNameHasError,
        valueChangeHandler: firstNameChangeHandler
    } = useInput(firstNameDefaultValue, isNotEmpty);
    const {
        value: lastNameValue,
        hasError: lastNameHasError,
        valueChangeHandler: lastNameChangeHandler
    } = useInput(lastNameDefaultValue, isNotEmpty);
    const {
        value: usernameValue,
        hasError: usernameHasError,
        valueChangeHandler: usernameChangeHandler
    } = useInput(usernameDefaultValue, isUsernameValid);
    const {
        value: passwordValue,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler
    } = useInput(passwordDefaultValue, isPasswordValid);
    const {
        value: confirmPasswordValue,
        hasError: confirmPasswordHasError,
        valueChangeHandler: confirmPasswordChangeHandler
    } = useInput(confirmPasswordDefaultValue, isConfirmPasswordValid);

    const showPasswordChangeHandler = () => {
        setShowPasswordChecked(prevState => !prevState);
    }
    const nextButtonClickHandler = () => {
        setFormError('');
        if (firstNameHasError || lastNameHasError || usernameHasError || passwordHasError || confirmPasswordHasError) {
            setFormHasErrors(true);
            setFormError('Please fill in the required field(s)');
            scrollToTop();
            return;
        }
        if (isFederated) {
            updatePersonContext().then(() => {
                goToNextPage();
            });
        } else {
            validateUsernameRequest({
                url: validateUsernameUrl(person?.account.personVistageId),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: { "username": usernameValue }
            }, () => {
                updatePersonContext().then(() => {
                    goToNextPage();
                });
            }, (error: any) => {
                switch (+error.statusCode) {
                    case 409:
                        setFormHasErrors(true);
                        setFormError('That username is already taken, sorry.  Please select a different username.');
                        scrollToTop();
                        return;
                    default:
                        setFormHasErrors(true);
                        setFormError('Username validation is temporarily unavailable. Please try again in a few minutes.');
                        scrollToTop();
                        return;
                }
            });
        }
    }
    const scrollToTop = () => {
        document.getElementById('app-header')?.scrollIntoView({behavior: 'smooth'});
    };
    const updatePersonContext = async () =>  {
        await updateStep1Details(firstNameValue, lastNameValue, usernameValue, passwordValue);
    };
    return (
        <Fragment>
            <FormHeader heading="Create your account" />
            <FormContent>
                {formHasError && <p className={classes['form-error-description']}>{formError}</p>}
                <Input
                    inputLabel="First Name"
                    inputType="text"
                    inputRequired={true}
                    inputReadOnly={false}
                    inputValue={firstNameValue}
                    inputStyle={formHasError && firstNameHasError ? 'input-textbox-error' : ''}
                    inputOnChange={firstNameChangeHandler}
                    maxLength={100}
                />
                {formHasError && firstNameHasError && <p className={classes['error-description']}>Please enter first name</p>}
                <Input
                    inputLabel="Last Name"
                    inputType="text"
                    inputRequired={true}
                    inputReadOnly={false}
                    inputValue={lastNameValue}
                    inputStyle={formHasError && lastNameHasError ? 'input-textbox-error' : ''}
                    inputOnChange={lastNameChangeHandler}
                    maxLength={100}
                />
                {formHasError && lastNameHasError && <p className={classes['error-description']}>Please enter last name</p>}
                <Input
                    inputLabel="Username"
                    inputType="text"
                    inputRequired={true}
                    inputReadOnly={isFederated}
                    inputValue={usernameValue}
                    inputStyle={formHasError && (usernameHasError || validatingUsernameHasError) ? 'input-textbox-error' : ''}
                    inputOnChange={usernameChangeHandler}
                    maxLength={150}
                />
                {
                    formHasError && usernameHasError &&
                    <p className={classes['error-description']}>
                        Usernames must be 6+ character<br/>
                        You may use letters, numbers and symbols +, -, _, @ and .<br/>
                        Select something easy to remember, like an email address
                    </p>
                }
                <Input
                    inputLabel="Password"
                    inputType={showPasswordChecked ? "text" : "password"}
                    inputRequired={true}
                    inputReadOnly={false}
                    inputValue={passwordValue}
                    inputStyle={formHasError && passwordHasError ? 'input-textbox-error' : ''}
                    inputOnChange={passwordChangeHandler}
                />
                {
                    formHasError && passwordHasError &&
                    <p className={classes['error-description']}>
                        Passwords are case-sensitive and must be 8+ character<br/>
                        You may use letters, numbers, and symbols @ ! $ + %<br/>
                        You must include at least 1 uppercase letter, 1 lowercase letter and 1 number
                    </p>
                }
                <Input
                    inputLabel="Confirm"
                    inputType={showPasswordChecked ? "text" : "password"}
                    inputRequired={true}
                    inputReadOnly={false}
                    inputValue={confirmPasswordValue}
                    inputStyle={formHasError && confirmPasswordHasError ? 'input-textbox-error' : ''}
                    inputOnChange={confirmPasswordChangeHandler}
                />
                {formHasError && confirmPasswordHasError && <p className={classes['error-description']}>The Password and Confirm fields must match</p>}
                <div className={classes['show-password-row']}>
                    <input type="checkbox" onChange={showPasswordChangeHandler}/>
                    <label>Show Password</label>
                </div>
                <div className={classes['next-button-row']}>
                    <NextButton onClickHandler={nextButtonClickHandler}/>
                </div>
            </FormContent>
        </Fragment>
    );
};

export default CreateAccountStep1;
