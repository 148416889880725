import {FC, FormEvent, Fragment, useContext, useState} from "react";
import useInput from "../hooks/use-input";
import useHttp from "../hooks/use-http";
import {useParams} from "react-router-dom";
import FormHeader from "./layout/FormHeader";
import FormContent from "./layout/FormContent";
import Input from "./ui/Input";
import LoadingSpinner from "./ui/LoadingSpinner";
import SupportHelpText from "./SupportHelpText";
import {expiredTokenUrl, isEmailValid} from "../utils/Constants";
import classes from "./Forms.module.css";
import AppContext from "../store/app-context";

const ExpiredLink: FC = () => {
    const { token } = useParams();
    const { config } = useContext(AppContext);
    const [formHasError, setFormHasError] = useState(false);
    const [formError, setFormError] = useState('');
    const {
        value: emailValue,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler
    } = useInput('', isEmailValid);
    const {
        isLoading: httpIsLoading,
        sendRequest: httpSendRequest
    } = useHttp();
    const submitHandler = (e: FormEvent) => {
        e.preventDefault();
        setFormError('');
        if (emailHasError) {
            setFormError('Please complete the following required field(s).')
            setFormHasError(true);
            return;
        }
        httpSendRequest({
            url: expiredTokenUrl,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: {
                'email': emailValue,
                'token': token
            }
        }, () => {
            window.location.href = `${config?.myVistageUrl}`;
        }, () => {
            window.location.href = `${config?.myVistageUrl}`;
        });
    }
    return (
        <Fragment>
            {httpIsLoading && <LoadingSpinner />}
            <FormHeader heading={"Link Not Valid"} />
            <FormContent>
                <form onSubmit={submitHandler}>
                    {formHasError && <p className={classes['form-error-description']}>{formError}</p>}
                    <div className={classes.row}>The link to create your account has expired.</div>
                    <div className={classes.row}>
                        Please enter the email address you provided to Vistage and we'll send you a new welcome email with a link that will be valid for 30 days.
                    </div>
                    <Input
                        inputLabel={"Email Address"}
                        inputType={"text"}
                        inputRequired={true}
                        inputReadOnly={false}
                        inputValue={emailValue}
                        inputStyle={formHasError && emailHasError ? 'input-textbox-error' : ''}
                        inputOnChange={emailChangeHandler}
                        maxLength={100}
                    />
                    {formHasError && emailHasError &&
                        <p className={classes['error-description']}>
                            Please enter a valid email address
                        </p>
                    }
                    <div className={`${classes.row} ${classes['form-action-button']}`}>
                        <button type={"submit"} className={classes['submit-button']}>
                            Send
                        </button>
                    </div>
                    <SupportHelpText />
                </form>
            </FormContent>
        </Fragment>
    );
};

export default ExpiredLink
