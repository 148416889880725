import {FC} from "react";
import classes from './Card.module.css';

const Card: FC<{ styles?: string }> = ({ children, styles }) => {
    const elementStyles = styles || "";
    return (
        <div className={`${classes.card} ${elementStyles}`}>
            {children}
        </div>
    );
};

export default Card;
