import {FC, useContext} from "react";
import classes from "./Forms.module.css";
import AppContext from "../store/app-context";

const SupportHelpText: FC = () => {
    const { config } = useContext(AppContext);
    return (
        <div className={classes.row}>
            <p className="help-msg">If you need help, please contact Vistage Support at <a
                href="mailto:support@vistage.com">support@vistage.com</a>, 888.261.3911
                or <a href={config?.liveChatUrl} target="_blank" rel="noreferrer">live chat.</a></p>
        </div>
    );
};

export default SupportHelpText;
